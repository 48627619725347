import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const Igaming = () => {
  const headingbanner = {
    title: `iGaming`,
    content: `Platform to track affiliates and players performance with omni-channel tracking feature.`,
  };

  const data = {
    images: ["igaming1.webp", "igaming2.webp"],
    firstAlt: "Manage Affilliates",
    firstTitle: "Manage Affilliates",
    secondAlt: "Affilliate Activities",
    secondTitle: "Affilliate Activities",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["SaaS"],
      },
      {
        title: "What we did",
        text: ["User Research", "UI UX Designing"],
      },
      {
        title: "Platform",
        text: ["Web Application"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `The current affiliate and players performance management platform lacked a user-friendly experience, resulting in low engagement and conversion rates. It was observed that navigating through the platform was confusing convoluted, making it difficult for users to find necessary tools, reports and resources. This led to frustration and decreased usage of the platform, hindering their ability to effectively promote products and generate revenue for the company.`,
          `The dashboards and reports lacked clear overview of key metrics, making it hard for users to analyse their performance and make informed decisions.`,
        ],
      },
      {
        title: "Solution",
        content: [
          `Our initial focus was to revamp the platform's navigation structure. By employing a systematic approach, we reorganized tools, reports, and resources into clearly labelled categories and streamlined menus, ensuring a seamless and frustration-free navigation experience.`,
          `We crafted a user-centric dashboard that transformed data into actionable insights. Simultaneously, we elevated the reporting module's UX by incorporating visuals for key metrics and highlighting data that would be crucial for the users . The infusion of visually appealing design elements not only made reports more engaging but also simplified the process of deriving meaningful conclusions from complex data.`,
        ],
      },
    ],
    image: ["igaming-problem.webp"],
    firstAlt: "All Mockup Screens",
    firstTitle: "Project Screens",
  };

  const projectData = {
    title: `Smooth and organised onboarding of users`,
    para: [
      `The onboarding process is divided into clear, sequential steps. Each step is accompanied by a short description, guiding the user on what to expect and why each step is important. This helps prevent overwhelm and gives users a sense of progression.`,
      `By following this well-structured onboarding process, users can effortlessly add their brands, products, and campaigns, ensuring a smooth and efficient experience that sets them up for success on the platform.`,
    ],
    content: {
      image: ["igaming-process-1.webp"],
      imageAlt: "UI UX of Login Screen of a Digital Signature Platform",
      imageTitle: "Login Screen",
      para: [
        `Each step seamlessly transitions to the next, with clear "Next" buttons and progress indicators. This reduces confusion and helps users maintain a sense of direction.Before finalizing, users are presented with a summary of their input across all steps.`,

        `Once users complete the onboarding, they receive a confirmation message indicating their successful setup. They're then directed to their Homepage where they can start configuring and managing their brands, products, and campaigns.`,
      ],
    },
    content2: {
      image: ["igaming-process-2.webp"],
      imageAlt: "UI Design Of Document Management Screen",
      imageTitle: "Document Management Screen Design",
      text: `The home screen offers a personalized experience by prominently displaying the required actions a user need to take. It provides clear "Next Steps" suggestions guiding them on what to do next. To prevent any sense of confusion, a dedicated screen banner ensures easy access to assistance, creating an intuitive navigation experience that empowers users to efficiently explore the platform, configure settings, and seek help when needed.`,
    },
  };

  const projectData2 = {
    title: `User-friendly Dashboard <br/>for Enhancing The UX`,
    para: [
      `Employing dynamic visual elements such as interactive graphs, charts, and customizable widgets, user can gain an at-a-glance view of their performance metrics. They could personalize their dashboard, prioritizing the information most pertinent to their goals.`,
      `This level of engagement leads to more informed decision-making and a more efficient understanding of data, making interactive visuals a valuable component of any dashboard user interface.`,
    ],
    content: {
      image: ["igaming-process-3.webp"],
      imageAlt: "UI UX Design To Sign Document Digitally",
      imageTitle: "E-Sign Documents",
    },
  };

  const projectData3 = {
    title: `effectively Manage affiliates, Brands & campaigns`,
    para: [
      `Through a structured information architecture, actions were systematically grouped, ensuring intuitive navigation. Grid layouts were strategically employed to facilitate quick and effortless scanning of information, presenting data in an organized manner.`,
      `For user assistance, prominent screen banners were implemented, providing immediate access to help resources whenever required. The process of adding and managing affiliates, brands and products was purposefully differentiated, ensuring a clear and relatable flow for users. This deliberate approach enhanced organization, enabling users to seamlessly interact with the platform and manage their tasks efficiently.`,
    ],
    content: {
      image: [
        "igaming-ui-1.webp",
        "igaming-ui-2.webp",
        "igaming-ui-3.webp",
        "igaming-ui-4.webp",
        "igaming-ui-5.webp",
      ],
      firstAlt: "Manage Brands",
      firstTitle: "Manage Brands",
      secondAlt: "Demo Campaign",
      secondTitle: "Demo Campaign",
      thirdAlt: "Add Campaign",
      thirdTitle: "Add Campaign",
      fourthAlt: "Test Media",
      fourthTitle: "Test Media",
      fifthAlt: "Create Invoice",
      fifthTitle: "Create Invoice",
    },
  };

  const conclusionData = [
    {
      para: [
        `Our meticulous design process for the affiliate management platform was driven by the goal of optimizing the management of diverse activities while simultaneously elevating the user experience. Through careful planning, intuitive interface design, and robust backend integration, we created a platform that seamlessly connects affiliates, tracks performance, and simplifies communication.`,
        `This transformative approach has not only streamlined the intricate processes involved in affiliate management but has also significantly improved the overall user experience. The platform's enhanced usability, real-time tracking, and simplified reporting mechanisms have contributed to a remarkable transformation, fostering increased engagement, efficiency, and ultimately, driving superior results for the platform and its users. `,
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={1} />
      </div>
    </Layout>
  );
};

export default Igaming;

export const Head = () => (
  <Seo
    title="iGaming- Platform to track Affiliates Performance| Octet"
    description="A platform to track affiliates’ and players’ performance with omni-channel tracking feature. We offered UI UX services on User Research and UI UX Designing."
  />
);
